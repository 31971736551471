export const tableStyles = {
  // https://daisyui.com/components/table/
  sizes: {
    // daisy style
    xs: "table-xs",
    sm: "table-sm",
    md: "table-md",
    lg: "table-lg",
    //custom
  },
  zebra: "table-zebra",
  stickyRows: "table-pin-rows",
  stickyCols: "table-pin-cols",
};
